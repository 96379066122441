.story-thumb {
  @apply pr-2;
  box-sizing: content-box;
  width: 100px;
  height: 100px;
  &::before {
    content: "";
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 10px);
    height: 50px;
    max-height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparentize(#1a74e9, 1),
      transparentize(#1a74e9, 0.1)
    );
  }
  img {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }
}
