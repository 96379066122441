@tailwind base;

@font-face {
  font-family: "Brix Sans";
  font-weight: bold;
  src: url("./static/fonts/Brix Sans Bold/BrixSansBold.otf");
}
@font-face {
  font-family: "Brix Slab";
  font-weight: 300;
  src: url("static/fonts/Brix Slab Light/BrixSlab-Light.otf");
}

@tailwind components;

@tailwind utilities;

::placeholder {
  @apply font-serif;
}
