.sidebar {
  &--wrapper {
    @media (max-width: 1023px) {
      @apply my-2;

      z-index: 30;
      position: fixed;
      height: calc(100% - 77px) !important;
      transform: translateX(-100%);
      transition: transform ease-out 250ms;
      max-width: calc(100% - 40px);

      // This acts as the inner shadow
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 10px;
        z-index: 1;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        );
      }

      &__expanded {
        transform: translateX(0);
      }
    }
    @screen md {
      height: calc(100% - 130px) !important;
    }
    @screen lg {
      @apply static;
      @apply my-0;
      @apply shadow-lg;
      height: 100% !important;
    }
  }
  &--toggle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
    @apply bg-gray-300 py-3 px-2 shadow-md cursor-pointer;
    @screen lg {
      @apply hidden;
    }
  }
}
