.main-header {
  width: 100%;
  padding-left: 34px;
  padding-right: 34px;
  @apply flex;
  @apply justify-between;
  @apply items-center;
  width: 100%;
  @apply bg-blue-900;
  @apply text-blue-200;
  @apply shadow-inner;
  @apply text-blue-100;
  @apply py-1;
  @apply px-4;
  @screen md{
    @apply py-3;
    @apply px-6;

  }
}
.main-header--container {
  overflow: hidden;
}
.main-header--mobilizable {
  @media (max-width: 767px) {
    @apply transform;
    @apply transition-transform;
    transition-duration: 250ms;
    @apply fixed;
    @apply right-0;
    @apply top-0;
    @apply bg-blue-900;
    @apply z-40;
    @apply px-6;
    @apply items-center;
    @apply flex;
    @apply flex-col;
    @apply items-stretch;
    @apply justify-start;
    margin-top: 57px;
    height: calc(100vh - 57px);
    overflow-y: auto;
    @apply max-w-full;

    @apply pt-6;

  }
  @screen md{
    @apply flex-row;
    @apply flex;
    @apply items-center;
    width: 100%;
    @apply justify-between
  }
}
