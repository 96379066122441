.glazing {
  animation: glaze;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
}

@keyframes glaze {
  0% {
    background-color: transparent;
  }
  50% {
    @apply bg-blue-200;
  }
  100% {
    background-color: transparent;
  }
}
