.main-search-bar {
  position: relative;
  &--icon {
    line-height: 1.3;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    width: 16px;
    z-index: 10;

    margin: auto;

    color: black;
  }
  &--close {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    line-height: 1;
    outline: none;
  }
  /** @todo is the color black? **/
  &--input {
    width: 100%;
    //border: 1px solid theme("colors.gray.600");
    padding: 10px 40px;
    border-radius: 8px;
    line-height: 1.3;
    color: black;
    outline: none;
    //box-shadow: 0 2px 4px rgba(0,0,0,.5), inset 0 1px 3px rgba(0,0,0,.5);
    &::placeholder {
      font-family: "Brix Slab", serif;
    }
  }
}
