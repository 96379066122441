.partial-star{
  overflow: hidden;
  left: 0;
  position: absolute;
  @for $i from 1 to 9{
    &__w-#{$i}{
      width: $i*10%;
    }
  }
}
